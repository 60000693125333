import * as React from "react";
import Navigation from "../components/Navigation";
import UsabilityTestingSeo from "../components/SEO/UsabilityTestingSeo";
import "../styles/style.css";

const UsabilityTesting = () => {
  return (
    <main>
      <div className="content">
        <UsabilityTestingSeo></UsabilityTestingSeo>
        <Navigation></Navigation>
        <div className="about__section-1">
          <div>
            <div className="glass">
              <h1>Usability Testing</h1>
            </div>
          </div>
          <div className="lat__bullet-circle--container">
            <div>
              <div className="bullet-circle"></div>
              <p>
                Usability testing gives us an insight into the mind of a user
              </p>
            </div>
            <div>
              <div className="bullet-circle"></div>
              <p>Allows us to see if we meet business and user expectations</p>
            </div>
          </div>
        </div>
        <div className="lat__advice-grid">
          <div className="glass mobile-span">
            <div className="step-1">1</div>
            <div className="lat__panel-title">Create a plan</div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Understand what you need to test (this will be based on business
                needs and design ideas).
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Narrow down the type of user you need to test on (based on prior
                research/persona)
              </div>
            </div>
          </div>
          <div className="glass mobile-span">
            <div className="step-2">2</div>
            <div className="lat__panel-title">Perform test</div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Never "lead" the user to the goals you desire
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Create an environment that is as realistic as possible
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Analyse time it takes user to perform said task/difficulty in
                per- forming tasks
              </div>
            </div>
          </div>
          <div className="glass mobile-span">
            <div className="step-3">3</div>
            <div className="lat__panel-title">Compile Data</div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Remember results are not an attack on your ability.
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Put together a report based on your findings
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Include your methods, user profiles, settings and ideal results
                next to actual results.
              </div>
            </div>
          </div>
          <div className="glass glass-title">Things to remember</div>
          <div className="glass span-2">
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                You can do user ability testing as many times as you feel needed
                for the successfulness of the project
              </div>
            </div>
          </div>
          <div className="tips">
            <i className="fas fa-question"></i>
          </div>
          <div className="glass span-2">
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                DID YOU KNOW? : If a user struggles to complete a task, this
                great for you! It means the user ability test was able to help
                you identify a flaw in the user experience and you'll have a
                better final product.
              </div>
            </div>
          </div>
          <div className="brain">
            <i class="fas fa-brain"></i>
          </div>
          <div className="full-width"></div>
        </div>
      </div>
    </main>
  );
};

export default UsabilityTesting;
